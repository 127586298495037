const darkTheme = {
  "--fontColor": "#ffffff",
  "--fontDarkColor": "#ffffff",
  "--fontLightColor": "#ffffff",
  "--borderColors": "#ffffff",
  "--borderColorDark": "#ffffff",
  "--shadowColor": "#000000",
  "--BackgroundColor": "#2c2f31",
  "--submenuBackgroundColor": "#2c2f31",
  "--cardBackgroundColor": "#1F1F1F",
  "--cardHighlightBackgroundColor": "#323131",
  "--DropDownMenuBackgroundColor": "#515761",
  "--selectedBackgroundColor": "#333d4e",
  "--socialIconColor": "#ffffff",
  "--HoverOver": "#47556d",
  "--disableColor": "#bebbbb",
  "--backgroundSubscriptionCard": "#7B3718",
  "--primaryColorGradient": "204,	102, 0",
  "--backgroundSync": "#2d2520",
  "--primaryDarkColor": "#8b4000",
  "--tagPickerBackgroundColor": "#1f1f1f",
  "--tagPickerInputBackgroundColor": "#2c2f31",
  "--primaryTanColor": "#a97850",
  "--primaryTanHover": "#bb9270",
};

const lightTheme = {
  "--fontColor": "#717171",
  "--fontDarkColor": "#000000",
  "--fontLightColor": "#979797",
  "--borderColors": "#e5e4e4",
  "--borderColorDark": "#bfbdbd",
  "--shadowColor": "#f2f2f2",
  "--BackgroundColor": "#f9f9f9",
  "--submenuBackgroundColor": "#ffffff",
  "--cardBackgroundColor": "#ffffff",
  "--cardHighlightBackgroundColor": "#fff9f5",
  "--DropDownMenuBackgroundColor": "#f2f2f2",
  "--selectedBackgroundColor": "#e3e3e3",
  "--socialIconColor": "#979797",
  "--HoverOver": "#ededed",
  "--disableColor": "#d7d5d5",
  "--backgroundSubscriptionCard": "#edb590",
  "--primaryColorGradient": "255,	102, 0",
  "--backgroundSync": "#ffe0cc",
  "--primaryDarkColor": "#b54419",
  "--tagPickerBackgroundColor": "#ffffff",
  "--tagPickerInputBackgroundColor": "#e3e3e3",
  "--primaryTanColor": "#e8b98A",
  "--primaryTanHover": "#e4ac75",
};

export function applyColorTheme(colorMode) {
  const theme = colorMode === "dark" ? darkTheme : lightTheme;
  Object.keys(theme).forEach((key) => {
    document.documentElement.style.setProperty(key, theme[key]);
  });
}
