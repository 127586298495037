import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, keyframes } from "@mui/material/styles";
import { ReactComponent as Dino } from "../Assets/dinoThing.svg";

/******************/
// Keyframes for a hopping animation
/***/
const hopAnimation = keyframes`
  0%   { left: 0%;   transform: translateY(0); }
  5%   { left: 5%;  transform: translateY(-20px); }
  10%  { left: 10%;  transform: translateY(0); }
  15%  { left: 15%;  transform: translateY(-20px); }
  30%  { left: 30%;  transform: translateY(0); }
  35%  { left: 35%;  transform: translateY(-20px); }
  40%  { left: 40%;  transform: translateY(0); }
  45%  { left: 45%;  transform: translateY(-20px); }
  50%  { left: 50%;  transform: translateY(0); }
  55%  { left: 55%;  transform: translateY(0); }
  60%  { left: 60%;   transform: translateY(0); }
  65%  { left: 65%;  transform: translateY(-20px); }
  70%  { left: 70%;  transform: translateY(0); }
  75%  { left: 75%;  transform: translateY(-20px); }
  90%  { left: 90%;  transform: translateY(0); }
  95%  { left: 95%;  transform: translateY(-20px); }
  100%  { left: 100%;  transform: translateY(0); }
`;

// Container to hold the icon
const HopperContainer = styled("div")({
  position: "relative",
  marginTop: "30px",
});

// Utility to generate dynamic keyframes for color cycling based on your color array
const generateColorKeyframes = (colors) => {
  const step = 100 / colors.length;
  let keyframeString = "";
  colors.forEach((color, index) => {
    const percentage = index * step;
    keyframeString += `${percentage}% { fill: ${color}; } `;
  });
  keyframeString += `100% { fill: ${colors[0]}; }`;
  return keyframes`${keyframeString}`;
};

// HoppingIcon rendered as an inline SVG that cycles its fill color and hops
const HoppingIcon = styled("svg", {
  shouldForwardProp: (prop) => prop !== "customColor",
})(({ customColor }) => {
  const colors = customColor || ["#FF7F0E", "#2CA02C", "#D62728"];
  const colorAnimation = generateColorKeyframes(colors);
  return {
    position: "absolute",
    bottom: "100%", // position directly above the progress bar
    left: 0,
    width: 24,
    height: 24,
    // Apply both hop and color-change animations simultaneously
    animation: `${hopAnimation} 5s linear infinite, ${colorAnimation} 5s linear infinite`,
  };
});

const CustomLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) =>
    prop !== "customColor" && prop !== "barHeight" && prop !== "isRainbow",
})(({ barHeight, customColor, isRainbow }) => ({
  height: barHeight || 10,
  borderRadius: 5,
  overflow: "hidden",
  backgroundColor: "transparent",
  "&& .MuiLinearProgress-bar1Indeterminate, && .MuiLinearProgress-bar2Indeterminate":
    {
      backgroundImage: isRainbow
        ? `linear-gradient(270deg, ${customColor.join(", ")}) `
        : "none",
      backgroundColor: isRainbow ? "transparent" : `${customColor}  !important`,
      backgroundSize: isRainbow ? "200% 200%" : "auto",
    },
  "&& .MuiLinearProgress-bar1Indeterminate": {
    animationDuration: "5s",
  },
  "&& .MuiLinearProgress-bar2Indeterminate": {
    animationDuration: "0s",
  },
}));

const RainbowSpinner = ({ size = 40, thickness = 4, colors = [] }) => {
  const radius = (size - thickness) / 2;
  const circumference = 2 * Math.PI * radius;
  const gradientId = `rainbow-spinner-gradient-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  return (
    <svg
      width={size}
      height={size}
      style={{ animation: "rotate 5s linear infinite" }}
    >
      <defs>
        <linearGradient id={gradientId}>
          {colors.map((color, index) => (
            <stop
              key={index}
              offset={`${(index / (colors.length - 1)) * 100}%`}
              stopColor={color}
            />
          ))}
        </linearGradient>
      </defs>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={`url(#${gradientId})`}
        strokeWidth={thickness}
        strokeDasharray={circumference}
        strokeDashoffset={circumference * 0.75} // Creates a gap for a “spinner” look
        style={{
          transformOrigin: "center",
          animation: "dash 5s ease-in-out infinite",
        }}
      />
      {/* Inline styles for the keyframes animations */}
      <style>
        {`
          @keyframes rotate {
            100% { transform: rotate(360deg); }
          }
          @keyframes dash {
            0% {
              stroke-dashoffset: ${circumference};
            }
            50% {
              stroke-dashoffset: ${circumference * 0.25};
              transform: rotate(45deg);
            }
            100% {
              stroke-dashoffset: ${circumference};
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </svg>
  );
};

/**
 * LoadingIndicator component props:
 * - variant: 'bar' (linear progress) or 'spinner' (circular progress)
 * - colors: a single color (string) or an array of colors for rainbow mode
 * - size: spinner size (for circular progress)
 * - thickness: spinner stroke thickness
 * - barHeight: height of the linear progress bar
 */
const LoadingIndicator = ({
  variant = "bar",
  colors = "#1976d2",
  size = 40,
  thickness = 4,
  barHeight = 10,
}) => {
  // Determine if we’re in “rainbow” mode (more than one color in the array).
  const isRainbow = Array.isArray(colors) && colors.length > 1;
  let colorValue = colors;
  if (Array.isArray(colors) && colors.length === 1) {
    colorValue = colors[0];
  }

  if (variant === "spinner") {
    // Use the custom RainbowSpinner if multiple colors are provided.
    if (isRainbow) {
      return (
        <>
          <RainbowSpinner size={size} thickness={thickness} colors={colors} />
        </>
      );
    }
    // Otherwise, use MUI’s CircularProgress with a single color.
    return (
      <CircularProgress
        size={size}
        thickness={thickness}
        style={{ color: colorValue }}
      />
    );
  } else if (variant === "hopper") {
    return (
      <HopperContainer>
        <HoppingIcon customColor={isRainbow ? colors : [colorValue]}>
          <Dino />
        </HoppingIcon>
      </HopperContainer>
    );
  } else {
    // For the linear progress bar variant.
    return (
      <CustomLinearProgress
        variant="indeterminate"
        barHeight={barHeight}
        customColor={isRainbow ? colors : colorValue}
        isRainbow={isRainbow}
      />
    );
  }
};

export default LoadingIndicator;
