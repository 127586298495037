import React from "react";
import axios from "axios";
import { Auth } from "aws-amplify";

import { store } from "../Utils/Store/Store";
import { frontEndLogout, logout, resetAuth } from "../Utils/Features/authSlice";
import { reset } from "../Utils/Features/librarySlice";
import { resetSyncStatus } from "../Utils/Features/amazonSyncSlice";

import toastMessage from "../helperFunctions/toastMessage";
import { customLog } from "../helperFunctions/customLogger";

export const History = {
  navigate: null,
};

const resetSlices = async () => {
  await store.dispatch(reset());
  await store.dispatch(resetSyncStatus());
};

const backendLogout = async () => {
  await store.dispatch(logout());
  await resetSlices();
};

export const amplifyLogout = async () => {
  store.dispatch(frontEndLogout());
  try {
    await Auth.signOut();
  } catch (err) {
    console.log("error:", err);
  }
};

export const handleLogout = async () => {
  try {
    await backendLogout();
    amplifyLogout();
    History.navigate("/");
  } catch (error) {
    console.log("Error signing out: ", error);
  }
};

const specialMessageEndpoints = {
  "/profile/update": "processing your profile data",
  "/library/fetch/public?":
    "loading the book, showing your previously selected book",
  "/library/fetch/progress?":
    "loading the book, showing your previously selected book",
  "/payment/checkout": "handling the free subscription",
  "/library/update/privacy": "changing your book privacy",
  "library/markmap": "creating Markmap on your book",
};

const noToastEndpoints = ["/sync-", "/content/export"];

const globalErrorHandler = (error) => {
  const responseStatus = error?.response?.status;
  const errorMessage =
    error?.response?.data?.message ||
    error?.message ||
    "An unexpected error occurred.";
  if (responseStatus === 401) {
    customLog("Not authorized");
    store.dispatch(resetAuth());
  } else {
    // TODO: error logging somehow?
    customLog("server error:", error?.message);
    const requestUrl = error?.config?.url || error?.response?.config?.url;
    let toast = true;
    noToastEndpoints.forEach((endpoint) => {
      if (requestUrl?.includes(endpoint)) {
        toast = false;
      }
    });

    if (toast) {
      var message = null;
      Object.keys(specialMessageEndpoints).forEach((endpoint) => {
        if (requestUrl?.includes(endpoint)) {
          message = specialMessageEndpoints[endpoint];
        }
      });

      const formattedErrorMessage = errorMessage
        .split("\n")
        .map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));

      if (message) {
        toastMessage(
          "error",
          5000,
          <div>
            Oops! Looks like we hit a snag {message}.
            <br />
            <br />
            {formattedErrorMessage}
            <br />
            <br />
            Cheers,
            <br />
            DeepRead Team
          </div>
        );
      } else if (responseStatus === 403) {
        toastMessage(
          "info",
          5000,
          <div>
            Oops! Looks like this content you are trying to access is not
            public.
            <br />
            <br />
            Cheers,
            <br />
            DeepRead Team
          </div>
        );
      } else {
        toastMessage(
          "error",
          5000,
          <div>
            Oops! It seems like there's a hiccup on our end. 🙈
            <br />
            <br />
            No worries though! Please give it another go a little later. Our
            team is already on it to get things back on track.
            <br />
            <br />
            Thanks a Brunch for your patience and understanding!
            <br />
            <br />
            Cheers,
            <br />
            DeepRead Team
          </div>
        );
      }
    }
  }
};

export const axiosInterceptor = axios.interceptors.response.use(
  undefined,
  (error) => {
    globalErrorHandler(error);

    if (error?.response && error?.response?.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error?.message);
  }
);
