// ChromeExtensionConnector.js
import axios from "axios";
import { Auth } from "aws-amplify";
import AmazonConnector from "../Connectors/AmazonConnector";
import { installExtensionToast } from "./ExtensionInstallationPrompt";

import { toast } from "react-toastify";
import { store } from "../../Utils/Store/Store";
import {
  fetchBookList,
  fetchBook,
  resetLibrary,
  fetchLibrary,
  resetViewData,
  fetchListViewData,
} from "../../Utils/Features/librarySlice";
import { getHeaders } from "../../Utils/Features/authSlice";
import {
  startSync,
  finishSync,
  failSync,
  enableSync,
  setSyncType,
} from "../../Utils/Features/amazonSyncSlice";

import { customLog } from "../../helperFunctions/customLogger";
import {
  getEnvironmentDependentStringVariable,
  getApiUrl,
} from "../../helperFunctions/envVars";

export const SyncOrigin = {
  Sidebar: "sidebar",
  Book: "book",
  Highlight: "highlight",
};

// Max retry attempts, to re-authenticate the user
const MAX_RETRIES = 2;

var extensionId = getEnvironmentDependentStringVariable(
  "REACT_APP_CHROME_EXTENSION_ID"
);
const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";

/* global chrome */
class ChromeExtensionConnector {
  static async checkExtensionIsInstalled() {
    try {
      let isInstalled = false;
      let message = "";

      // Check if the extension is installed
      if (typeof chrome !== "undefined" && chrome.runtime) {
        // For Chrome
        await new Promise((resolve) => {
          chrome.runtime.sendMessage(
            extensionId,
            { event: "buttonClicked" },
            (response) => {
              isInstalled = !!response; // Convert response to boolean
              message = `checkExtensionIsInstalled, isInstalled: ${isInstalled}`;
              customLog(message);
              resolve();
            }
          );
        });
      } else {
        toast.info(
          "Please use Chrome or Brave browser to synchronize your books (FireFox will be supported later).",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: false,
          }
        );
      }

      customLog("checkExtensionIsInstalled, Promise resolves.");
      return { isInstalled, message };
    } catch (error) {
      console.log(error);
      return {
        isInstalled: false,
        message: "An error occurred while checking extension installation.",
      };
    }
  }

  static async messageTheUserIfSomethingIsWrongWithTheExtension() {
    try {
      // Check if extension is installed
      const { isInstalled, message } = await this.checkExtensionIsInstalled();

      if (!isInstalled) {
        installExtensionToast(toast.POSITION.BOTTOM_RIGHT);
        console.log(message);
        store.dispatch(enableSync());
        throw new Error(
          "ChromeExtensionConnector: The browser extension is not installed."
        );
      }
    } catch (error) {
      console.log(error);
      store.dispatch(enableSync());
      throw error;
    }
  }

  static async SyncAmazonBooks(userId) {
    try {
      store.dispatch(
        setSyncType({ bookId: null, originView: SyncOrigin.Sidebar })
      );
      const amazonAuthenticatedUser = await AmazonConnector.authenticate();
      if (amazonAuthenticatedUser) {
        await AmazonConnector.authenticateFlowAndTriggerExtension(
          amazonAuthenticatedUser
        );

        await this.messageTheUserIfSomethingIsWrongWithTheExtension();

        customLog(
          "SyncAmzonBooks: Before sending the signal to our extension."
        );

        chrome.runtime.sendMessage(
          extensionId,
          {
            event: "SyncAmazonBooks",
            deepread_token: "",
            deepread_user: userId,
          },
          (response) => {
            customLog(
              `SyncAmazonBookResponse, userID: ${userId}`,
              response.message
            );
            this.postSyncBooks(response.cookies);
          }
        );

        this.showToastInfo(
          "Library Sync has started, please wait a little while and you will be notified when your synced books are available in the library."
        );

        store.dispatch(startSync());
      }
    } catch (error) {
      console.log("SyncAmazonBooks: error: ", error);

      this.showToastError(
        "Library Sync failed, check the DeepRead extension on your browser and/or try again later!"
      );

      // handleSyncError, clear cookies and logout the user if DeviceToken could not be fetched in backend
      SyncHandler.handleSyncError(error);
    }
  }

  static showToastSuccess(message) {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  static showToastInfo(message) {
    toast.info(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  static showToastError(message, keepOpen = false) {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: keepOpen ? false : 5000, // If keepOpen is true, set autoClose to false
    });
  }

  static async postSyncBooks(cookies) {
    let retryCount = 0;

    const attemptSync = async (cookies) => {
      if (!cookies) {
        console.error(
          "Error-SyncAmazonBooks: No cookies found with the extension, or extension not setup in browser."
        );
        this.showToastError(
          "Library Sync failed, check the DeepRead extension on your browser and/or try again later."
        );
        store.dispatch(failSync());
        return;
      }

      try {
        customLog("------- calling syncBooks -------- ");
        const res = await axios.post(
          `${apiRoot}/auth/sync-books`,
          { cookies },
          getHeaders()
        );

        const response = res.data;
        customLog(`Response from backend Sync-Books: ${response.message}`);

        if (response.status === 200) {
          this.showToastSuccess(
            "Amazon book sync is ready and you can see your books now in the library. " +
              response.message
          );
          store.dispatch(finishSync());
          store.dispatch(resetLibrary());
          store.dispatch(fetchLibrary());
          store.dispatch(fetchBookList());
          store.dispatch(enableSync());
        }
      } catch (error) {
        console.error("Error-SyncAmazonBooks on post to the backend:", error);

        // Check if we should retry
        if (retryCount < MAX_RETRIES) {
          retryCount++;

          // Show a toast indicating we're retrying
          toast.info(
            `Retrying sync... Attempt ${retryCount} of ${MAX_RETRIES}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );

          try {
            // Attempt to refresh authentication
            // await AmazonConnector.disconnect();
            await AmazonConnector.clearUserState();
            const user = await AmazonConnector.authenticate();

            if (user) {
              // If we successfully re-authenticated, retry the sync
              // Small delay to ensure everything is settled
              await new Promise((resolve) => setTimeout(resolve, 1000));
              return attemptSync(cookies);
            }
          } catch (authError) {
            console.error("Re-authentication failed:", authError);
            // If re-auth fails, fall through to error handling
          }
        }

        // If we've exhausted retries or re-auth failed, show error
        this.showToastError(
          retryCount > 0
            ? `Library Sync failed after multiple attempts.\n Please try to logout and login again.`
            : `Library Sync failed, please try to logout and login again.\n Possible error: ${error.message}`,
          true // keep it open for the user to not miss it
        );

        store.dispatch(failSync());

        // Handle sync error last, in case it involves logout
        SyncHandler.handleSyncError(error);
      }
    };

    // Start the first attempt
    await attemptSync(cookies);
  }

  static async SyncAmazonFullHighlights(bookId) {
    try {
      const amazonAuthenticatedUser = await AmazonConnector.authenticate();
      if (amazonAuthenticatedUser) {
        await AmazonConnector.authenticateFlowAndTriggerExtension(
          amazonAuthenticatedUser
        );

        await this.messageTheUserIfSomethingIsWrongWithTheExtension();

        customLog(
          `MYLIBRARY: Before sending the signal to our extension. book_id: ${bookId}`
        );

        chrome.runtime.sendMessage(
          extensionId,
          {
            event: "SyncAmazonHighlights",
            deepread_token: "",
            deepread_user: "",
            deepread_book_id: bookId,
          },
          (response) => {
            customLog("SyncAmazonHighlights ", response.message);
            this.postSyncFullHighlights(response.cookies, bookId);
          }
        );

        this.showToastInfo(
          "Full Highlights Sync has started, please wait a little while and you will be notified when your full highlights are available."
        );
      }
    } catch (error) {
      console.log("Error authenticating: ", error);
      this.showToastError(
        "Full Highlights Sync failed, please try again later."
      );

      // handleSyncError, clear cookies and logout the user if DeviceToken could not be fetched in backend
      SyncHandler.handleSyncError(error);
    }
  }

  static async postSyncFullHighlights(cookies, bookId, callOriginView) {
    if (cookies !== null && cookies !== undefined) {
      axios
        .post(
          `${apiRoot}/auth/sync-fullhighlights`,
          {
            book_id: bookId,
            cookies: cookies,
          },

          getHeaders()
        )
        .then(async (res) => {
          const response = res.data;
          customLog(
            `Response from backend Sync-FullHighlights: ${response.message}`
          );
          if (response.status === 200) {
            this.showToastSuccess(
              "Full Highlights sync is ready and you can see your highlights now: " +
                response.message
            );
            if (callOriginView === SyncOrigin.Book) {
              store.dispatch(resetViewData());
            } else {
              store.dispatch(fetchListViewData(bookId));
              store.dispatch(fetchBook(bookId));
            }
          }
        })
        .catch((error) => {
          console.error(
            "Error-SyncAmazonFullHighlights on post to the backend:",
            error
          );
          this.showToastError(
            "Full Highlights Sync failed, please try again later."
          );
          // alert that the sync has gone wrong
          // handleSyncError, clear cookies and logout the user if DeviceToken could not be fetched in backend
          SyncHandler.handleSyncError(error);
        });
    } else {
      console.error(
        "Error-SyncAmazonFullHighlights, no cookies found with the extension, or extension not setup in browser."
      );
      this.showToastError(
        "Full Highlights Sync failed, please try again later."
      );
    }
  }

  static async SyncAmazonSingleBook(bookId, callOriginView) {
    try {
      store.dispatch(
        setSyncType({ bookId: bookId, originView: callOriginView })
      );
      const amazonAuthenticatedUser = await AmazonConnector.authenticate();
      if (amazonAuthenticatedUser) {
        await AmazonConnector.authenticateFlowAndTriggerExtension(
          amazonAuthenticatedUser
        );

        await this.messageTheUserIfSomethingIsWrongWithTheExtension();

        customLog(
          "SyncAmazonSingleBook: Before sending the signal to our extension."
        );

        chrome.runtime.sendMessage(
          extensionId,
          {
            event: "SyncAmazonSingleBook",
            deepread_token: "",
            deepread_user: "",
          },
          (response) => {
            customLog(`${response.message}`);
            this.postSyncSingleBook(response.cookies, bookId, callOriginView);
          }
        );

        this.showToastInfo(
          "Single Book Sync has started, please wait a little while and you will be notified when your synced book is available in the library."
        );

        store.dispatch(startSync({ singleBook: true }));
      }
    } catch (error) {
      console.log("SyncAmazonSingleBook: error: ", error);

      this.showToastError(
        `Single Book Sync failed, check the DeepRead extension on your browser and/or try again later!\n 
        Possible error: ${error.message}`
      );
    }
  }

  static async postSyncSingleBook(cookies, bookId, callOriginView) {
    let retryCount = 0;

    const attemptSingleBookSync = async (cookies, bookId, callOriginView) => {
      if (!cookies) {
        console.error(
          "Error-SyncAmazonSingleBook, no cookies found with the extension, or extension not setup in browser."
        );
        this.showToastError(
          `Single Book Sync failed, check the DeepRead extension on your browser and/or try again later!\n 
          Possible error: No cookies found with the extension.`
        );

        store.dispatch(failSync());
        return;
      }

      try {
        const res = await axios.post(
          `${apiRoot}/auth/sync-book`,
          {
            book_id: bookId,
            cookies: cookies,
          },
          getHeaders()
        );

        const response = res.data;
        customLog(`Response from backend sync-book: ${response.message}`);

        if (response.status === 200) {
          ChromeExtensionConnector.showToastSuccess(response.message);
          store.dispatch(finishSync({ singleBook: true }));
          store.dispatch(enableSync());
          store.dispatch(fetchBook(bookId));

          // Attempt to sync full highlights
          this.postSyncFullHighlights(cookies, bookId, callOriginView);
          this.showToastInfo(
            "Full Highlights Sync has started, please wait a little while and you will be notified when your full highlights are available."
          );
        }
      } catch (error) {
        console.error(
          "Error-SyncAmazonSingleBook on post to the backend:",
          error
        );

        // Check if the book was not found
        if (
          error.message ===
          "The requested book could not be found in the Amazon book data."
        ) {
          this.showToastError(
            "The book could not be found in your Amazon library. Please check the book's availability.",
            true
          );
          store.dispatch(failSync());
          return;
        }

        // Check if we should retry
        if (retryCount < MAX_RETRIES) {
          retryCount++;

          // Show a toast indicating we're retrying
          toast.info(
            `Retrying single book sync... Attempt ${retryCount} of ${MAX_RETRIES}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );

          try {
            // Attempt to clear user state and reauthenticate
            await AmazonConnector.clearUserState();
            const user = await AmazonConnector.authenticate();

            if (user) {
              // If we successfully re-authenticated, retry the sync
              // Small delay to ensure everything is settled
              await new Promise((resolve) => setTimeout(resolve, 1000));
              return attemptSingleBookSync(cookies, bookId, callOriginView);
            }
          } catch (authError) {
            console.error("Re-authentication failed:", authError);
            // If re-auth fails, fall through to error handling
          }
        }

        // If we've exhausted retries or re-auth failed, show error
        this.showToastError(
          retryCount > 0
            ? `Single Book Sync failed after multiple attempts.\n Please logout and login again.`
            : `Single Book Sync failed.\n Please logout and login again.`,
          true // keep it open for the user to not miss it
        );

        store.dispatch(failSync());

        // Handle sync error last, in case it involves logout
        SyncHandler.handleSyncError(error);
      }
    };

    // Start the first attempt
    await attemptSingleBookSync(cookies, bookId, callOriginView);
  }

  static async restartAmazonSync() {
    const syncType = store.getState().amazonSync.syncType;
    const { userId } = store.getState().auth;
    if (!syncType || !userId) {
      console.error("Error restartAmazonSync: missing some state data");
    } else {
      try {
        if (syncType.originView === SyncOrigin.Sidebar) {
          await ChromeExtensionConnector.SyncAmazonBooks(userId);
        } else if (syncType.originView === SyncOrigin.Book) {
          await ChromeExtensionConnector.SyncAmazonSingleBook(
            syncType.bookId,
            syncType.originView
          );
        } else if (syncType.originView === SyncOrigin.Highlight) {
          await ChromeExtensionConnector.SyncAmazonSingleBook(
            syncType.bookId,
            syncType.originView
          );
        }
      } catch (error) {
        console.error("Error restartAmazonSync: ", error);
        this.showToastError(
          `Library Sync failed, check the DeepRead extension on your browser and/or try again later.`
        );
      } finally {
        store.dispatch(setSyncType(null));
      }
    }
  }
}

class SyncHandler {
  static handleSyncError(error) {
    if (error && error.response && error.response.status === 500) {
      const errorMessage = error.response.data.message || "";

      console.error(
        "ChromExtensionConnector.js: handleSyncError (cookies from kindle, trigger DeviceToken could not be fetched).",
        errorMessage
      );

      if (errorMessage.includes("DeviceToken could not be fetched")) {
        // Clear .amazon cookies
        this.clearAmazonCookies();

        // Logout user using Amplify
        this.amplifyLogout();

        // Additional actions if needed
        // ...

        // Dispatch actions or show messages as needed
        this.showToastError("Sync failed. Please try again later.");
        store.dispatch(failSync());
      }
    }
  }

  static clearAmazonCookies() {
    // Implement your logic to clear .amazon cookies
    // ...

    // Example code to clear all cookies
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  }

  static async amplifyLogout() {
    try {
      await Auth.signOut();
      // Additional cleanup steps if needed
      // ...
    } catch (logoutError) {
      console.error("Error during Amplify logout:", logoutError);
    }
  }
}

export default ChromeExtensionConnector;
