import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import FeedDetailsTop from "../FeedDetailsTop/FeedDetailsTop";
import FeedBottom from "../FeedBottom/FeedBottom";
import "./FeedDetailsCard.css";
import FeedHighlights from "../FeedHighlights/FeedHighlights";
import FeedNotes from "../FeedNotes/FeedNotes";
import FeedTab from "../FeedTab";
import S3Storage from "../../S3storage/s3storage";
import { DeepTabs } from "../../Common/Tabs";
import SuggestionBottom from "../FeedBottom/SuggestionBottom";

const FeedDetailsCard = ({ feedDataList, bookId }) => {
  const defaultMinTabHeight = 150;
  const defaultHeightWithoutImage = 200;
  const [tabMinHeight, setTabMinHeight] = useState(defaultHeightWithoutImage);
  const imageRef = useRef();

  const s3 = new S3Storage();

  const [imageDirection, setImageDirection] = useState("");

  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const FeedTabs = [
    {
      label: "Media",
      disabled: !feedDataList?.picture_link?.length > 0,
    },
    {
      label: "Notes",
      disabled: !feedDataList?.my_notes?.length > 0,
    },
    { label: "Highlights", disabled: !feedDataList?.highlight?.length > 0 },
  ];

  const setTabMinHeightWithCorrection = useCallback(
    (height) => {
      if (height < defaultMinTabHeight) {
        setTabMinHeight(defaultMinTabHeight);
      } else {
        setTabMinHeight(height);
      }
      if (height !== 0 && imageDirection === "") {
        calculateImageDirection();
      }
    },
    [imageDirection]
  );

  const calculateImageDirection = () => {
    const aspectRatio = imageRef?.current?.width / imageRef?.current?.height;

    // You can adjust the threshold based on your specific needs
    // This one is used to check that the image is vertical or horizontal and based on that it will add a class to display in full
    // width for horizontal images.
    const verticalThreshold = 1.0;

    if (aspectRatio) {
      const isVertical = aspectRatio < verticalThreshold;
      if (isVertical) {
        setImageDirection("vertical-image");
      } else {
        setImageDirection("horizontal-image");
      }
    }
  };

  useLayoutEffect(() => {
    calculateImageDirection();
    setTabMinHeightWithCorrection(imageRef?.current?.height);
  }, [setTabMinHeightWithCorrection]);

  const resetTabMinHeight = () => {
    setTabMinHeightWithCorrection(imageRef.current.offsetHeight);
  };

  return (
    <div
      className={`feedDetailsCard ${
        feedDataList.type === "suggestion" &&
        feedDataList.format !== "collection" &&
        "suggestion"
      }`}
    >
      <FeedDetailsTop feedDataList={feedDataList} uniqueKeyId={bookId} />
      <div className={`${isSmDown ? "" : "px-[44px]"}`}>
        {feedDataList.type === "suggestion" ? (
          <>
            <div className="pb-3"></div>
            {feedDataList?.picture_link &&
              feedDataList?.picture_link?.length > 0 && (
                <img
                  src={s3.getReloadImageLink(feedDataList?.picture_link)}
                  ref={imageRef}
                  alt="img"
                  className={
                    "object-cover object-center rounded-[8px] img-contain " +
                    imageDirection
                  }
                  onLoad={resetTabMinHeight}
                />
              )}
          </>
        ) : (
          <>
            {feedDataList.type === "idea" && (
              <DeepTabs tabsInfo={FeedTabs} pageType={"feed"}>
                {feedDataList?.picture_link &&
                feedDataList?.picture_link?.length > 0 ? (
                  <img
                    src={s3.getReloadImageLink(feedDataList?.picture_link)}
                    ref={imageRef}
                    alt="img"
                    className={
                      "object-cover object-center rounded-[8px] img-contain " +
                      imageDirection
                    }
                    onLoad={resetTabMinHeight}
                  />
                ) : (
                  <FeedTab tabMinHeight={tabMinHeight}>
                    No picture added yet! 📷 <br /> Check back later for some
                    visual treats!
                  </FeedTab>
                )}
                <FeedTab tabMinHeight={tabMinHeight}>
                  {feedDataList?.my_notes &&
                  feedDataList?.my_notes?.length > 0 ? (
                    <FeedNotes feedDataList={feedDataList} />
                  ) : (
                    <>
                      No notes added yet! 📝 <br /> Stay tuned for some
                      insightful comments!
                    </>
                  )}
                </FeedTab>
                <FeedTab tabMinHeight={tabMinHeight}>
                  {feedDataList?.highlight &&
                  feedDataList?.highlight?.length > 0 ? (
                    <FeedHighlights feedDataList={feedDataList} />
                  ) : (
                    <>
                      No highlights added yet! 🖍️ <br /> Hang tight for some
                      noteworthy moments!
                    </>
                  )}
                </FeedTab>
              </DeepTabs>
            )}
          </>
        )}

        {feedDataList.format === "collection" ? null : feedDataList.type ===
          "suggestion" ? (
          <SuggestionBottom feeditem={feedDataList} />
        ) : (
          <FeedBottom feeditem={feedDataList} />
        )}
      </div>
    </div>
  );
};

export default FeedDetailsCard;
