import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/system";
import { Menu, MenuItem } from "@mui/material";

import { iconProvider } from "../../helperFunctions/iconProvider";
import MenuIcon from "@mui/icons-material/Menu";

import LogoutIcon from "@mui/icons-material/Logout";
import DeepReadLogoHalf from "../../Assets/LogoHalfTransparent.png";

import {
  addBackButtonListener,
  removeBackButtonListener,
} from "../../helperFunctions/BackButtonHandler";

import DrawerModal, { getMenu } from "../DrawerModal/DrawerModal";
import { Overlay } from "../Overlay/Overlay";

import { handleLogout } from "../../helperFunctions/global";
import { menuItems } from "./Sidebar.jsx";

import {
  setCurrentPageTabData,
  setShowFilter,
} from "../../Utils/Features/currentPageTab";

import IdeaCardPage from "../../pages/IdeacardPage/IdeaCardPage.js";
import { closeCrossButtonStyle } from "../Drawer/Drawer.js";
import { handleDrawerClose } from "../../components/Drawer/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileImage } from "../Profile/ProfileImage.jsx";
import { isMenuActive } from "../../Utils/Features/userConfigSlice.js";
import IconWithActivity from "../Common/IconWithActivity.jsx";
import { store } from "../../Utils/Store/Store.js";

const getCurrentPage = () => {
  const locationArray = window.location.pathname.split("/");
  let currentMenu = "";
  menuItems.forEach((menuitem) => {
    if (locationArray.includes(menuitem.linkText)) {
      currentMenu = menuitem.linkText;
    }
  });
  return currentMenu;
};

const Bottombar = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ideaCard = useSelector((state) => state.persistentDrawerReducer.value);

  // needed to re-render when menu item activity changes
  // eslint-disable-next-line
  const menuActivity = useSelector((state) => state?.userConfig.menuActivity);

  const { currentPageTab, showFilter } = useSelector(
    (state) => state?.currentPageTab
  );

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (item) => {
    if (item.name === "Filter") {
      dispatch(setShowFilter(!showFilter));
      addBackButtonListener("/" + getCurrentPage());
    } else {
      dispatch(setCurrentPageTabData(item.page));
    }
  };

  const MenuNavigationAction = styled(BottomNavigationAction)({
    padding: "0px 4px",
    minWidth: "70px",
    color: "var(--fontColor)",
    "&.Mui-selected": {
      background: "var(--selectedBackgroundColor)",
      color: "var(--fontColor)",
      borderRadius: 12,
    },
  });

  const handleOverlayClose = () => {
    handleDrawerClose();
    removeBackButtonListener();
  };

  return (
    <>
      <Overlay isOpen={currentPageTab && showFilter}>
        <DrawerModal />
      </Overlay>
      {/* TODO: added temporarily here ?? better solution ?? */}
      <Overlay isOpen={!!ideaCard}>
        <CloseIcon
          fontSize="medium"
          style={{
            ...closeCrossButtonStyle,
          }}
          onClick={handleOverlayClose}
        />
        <IdeaCardPage />
      </Overlay>
      <BottomNavigation
        sx={{
          width: "100%",
          position: "fixed",
          zIndex: 23,
          bottom: 0,
          // TODO: is card background always same color as menu background
          //       or need another variable for this?
          background: "var(--cardBackgroundColor)",
          "& .MuiBottomNavigation-root": {
            background: "var(--cardBackgroundColor)",
          },
        }}
        showLabels
        value={getCurrentPage()}
        onChange={() => {
          removeBackButtonListener();
        }}
      >
        {menuItems
          .filter((item) => !item.onlySidebar)
          .map((menuitem) => (
            <MenuNavigationAction
              className={`${
                menuitem.name === "Filter" && !getMenu(currentPageTab)
                  ? "disable"
                  : ""
              }`}
              label={menuitem.name}
              value={menuitem.linkText}
              key={menuitem.name}
              component={Link}
              to={menuitem.path}
              icon={
                menuitem.isActiveIcon ? (
                  menuitem.activityIconClass !== undefined ? (
                    iconProvider(
                      menuitem.icon,
                      menuitem.isActive(menuitem.name)
                        ? menuitem.activityIconClass
                        : undefined
                    )
                  ) : (
                    <IconWithActivity
                      icon={iconProvider(menuitem.icon)}
                      hasActivity={menuitem.isActive(menuitem.name)}
                    />
                  )
                ) : menuitem.icon === "profile_icon" ? (
                  <ProfileImage isIcon="true" />
                ) : (
                  iconProvider(menuitem.icon)
                )
              }
              onClick={() => handleNavigation(menuitem)}
            />
          ))}
        <MenuNavigationAction
          label="More"
          key="More"
          onClick={openMenu}
          icon={<MenuIcon />}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-elevation": {
              top: "auto !important",
              left: "auto !important",
              right: "10px",
              bottom: "60px",
              background: "var(--BackgroundColor)",
              "& .MuiMenuItem-root": {
                color: "var(--fontColor)",
              },
            },
          }}
        >
          <MenuItem
            key="deepread"
            onClick={closeMenu}
            style={{ color: "var(--fontColor)" }}
            component={Link}
            to="https://deepread.com/"
          >
            <img
              src={DeepReadLogoHalf}
              alt="DeepRead"
              className="h-[22px] w-[22px] inline-block mr-3 rounded-[50%]"
            />
            Visit Website
          </MenuItem>
          <MenuItem
            key="notifications"
            onClick={closeMenu}
            style={{ color: "var(--fontColor)" }}
            component={Link}
            to="/notifications"
          >
            <span className="mr-3">
              <IconWithActivity
                icon={iconProvider("Notifications")}
                hasActivity={isMenuActive(store.getState(), "Notifications")}
              />
            </span>
            Notifications
          </MenuItem>
          <MenuItem
            key="profile"
            onClick={closeMenu}
            style={{ color: "var(--fontColor)" }}
            component={Link}
            to="/profile"
          >
            <span className="mr-3">
              <ProfileImage isIcon="true" />
            </span>
            Profile
          </MenuItem>
          <MenuItem
            style={{ color: "var(--fontColor)" }}
            onClick={handleLogout}
            key="logout"
          >
            <LogoutIcon
              sx={{ width: "22px", borderRadius: "50%" }}
              className="mr-3"
            />{" "}
            Logout
          </MenuItem>
        </Menu>
      </BottomNavigation>
    </>
  );
};

export default Bottombar;
