import { useSelector, useDispatch } from "react-redux";
import "./DrawerModal.css";
import ListViewMenu from "../../pages/ListViewMenu";
import TilesViewMenu from "../../pages/TilesViewMenu";
import LibraryViewMenu from "../../pages/LibraryViewMenu";
import FeedMenu from "../../pages/FeedMenu";
import {
  getPageNAme,
  setShowFilter,
} from "../../Utils/Features/currentPageTab";

// Just the page names as it should be, no hidden meaning there 8)
export const Pages = {
  ListView: "ListView",
  TilesView: "TilesView",
  BookView: "BookView",
  Library: "Library",
  Feed: "Feed",
  Profile: "Profile",
  MapView: "MapView",
  Notifications: "Notifications",
  Views: "Views",
  Collection: "Collection",
};

export const getMenu = (currentPageTab) => {
  switch (currentPageTab) {
    case Pages.ListView:
      return <ListViewMenu />;
    case Pages.TilesView:
      return <TilesViewMenu />;
    case Pages.Library:
      return <LibraryViewMenu />;
    case Pages.Feed:
      return <FeedMenu />;
    default:
      return null;
  }
};

export default function DrawerModal() {
  const dispatch = useDispatch();
  const closeHandle = () => {
    dispatch(setShowFilter(false));
  };

  const currentPageTab = useSelector(
    (state) => state?.currentPageTab?.currentPageTab
  );

  const NavigationHandler = () => {
    const menu = getMenu(currentPageTab);
    if (menu) {
      return menu;
    } else {
      return <span>Please Select Proper tab</span>;
    }
  };
  return (
    <div className="DrawerModalParent">
      <div className="headingSection">
        <span id="heading">{getPageNAme(currentPageTab)}</span>
        <span
          className="material-symbols-outlined"
          onClick={closeHandle}
          style={{ cursor: "pointer" }}
        >
          close
        </span>
      </div>
      <div className="contentsection">{NavigationHandler()}</div>
    </div>
  );
}
