export const exampleMarkmap = `---
title: "Book Overview: A New Perspective"
markmap:
  colorFreezeLevel: 2
---

# Book Overview: "A New Perspective"

## INTRODUCTION
- **Key idea 1:** Embrace *innovation* and explore new dimensions.
- **Key idea 2:** Challenge the status quo with ~~traditional methods~~.
- \`Setup:\` Begin with a strong foundation.
- [Learn more about markmap](https://markmap.js.org/) <!-- Example link -->

## CHAPTER 1: [Title]
- **Main point:** Establish a clear direction.
- *Supporting detail:* Use **bold** insights and _subtle_ nuances.
- **Action Items:**
  - [x] Confirm the primary concept.
  - [ ] Research background details.
- **Code in Action:**  
  \`\`\`js
  console.log("The adventure begins!");
  \`\`\`
- **Scientific Note:**  
  Explore the Pythagorean theorem: $a^2 + b^2 = c^2$ <!-- markmap: fold -->

## CHAPTER 2: [Title]
- **Key concept:** Integrate creative ideas with practical examples.
- *Example or takeaway:* See how \`processData()\` can transform raw information.
- **Steps to Success (Ordered List):**
  1. Collect essential data.
  2. Analyze the insights.
  3. Synthesize the outcomes.
  
## CONCLUSION
- **Recap:** Summarize the core ideas and steps taken.
- **Final insight:** Encourage continuous improvement and innovation.
- *Final insights Table:*

  | Aspect      | Continuous Improvement             | Innovation                           |
  |-------------|------------------------------------|--------------------------------------|
  | Approach    | Incremental, step-by-step changes  | Breakthrough, disruptive ideas       |
  | Focus       | Process optimization               | New products or business models      |
  | Methodology | Lean, Kaizen, Six Sigma            | Design thinking, agile experimentation|

- **Visual Reference:**  
  ![](https://images.pexels.com/photos/29342049/pexels-photo-29342049/free-photo-of-inspirational-lightbox-quote-wired-for-brilliance.jpeg?auto=compress&cs=tinysrgb&w=252&h=150&dpr=1)
`;
