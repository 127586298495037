import { createSlice } from "@reduxjs/toolkit";
import { selectBook } from "./librarySlice";
import { getMenu, Pages } from "../../components/DrawerModal/DrawerModal";
import { menuItems, viewItems } from "../../components/Sidebar/Sidebar";

export const CurrentPageTabSlice = createSlice({
  name: "CurrentPageTabData",
  initialState: {
    currentPageTab: null,
    showFilter: null,
  },
  reducers: {
    setCurrentPageTabData: (state, action) => {
      if (!action.payload) return;

      const allItems = [...menuItems, ...viewItems];

      const currentItem = allItems.find((item) => item.page === action.payload);

      if (currentItem.page === Pages.Views) {
        const viewAlreadySelected = viewItems.some(
          (view) => view.page === state.currentPageTab
        );

        if (!viewAlreadySelected) {
          state.currentPageTab = Pages.ListView;
        }
      } else if (state.currentPageTab !== action.payload) {
        state.currentPageTab = action.payload;
      }
      state.showFilter = false;
    },
    setShowFilter: (state, action) => {
      if (action.payload !== null) {
        state.showFilter = action.payload;
        if (!getMenu(state.currentPageTab)) {
          state.showFilter = false;
        }
      }
    },
  },
  extraReducers(builder) {
    // close filter when book is changed:
    builder.addCase(selectBook.fulfilled, (state) => {
      state.showFilter = false;
    });
  },
});

export const { setCurrentPageTabData, setShowFilter } =
  CurrentPageTabSlice.actions;

export default CurrentPageTabSlice.reducer;

export const getPageNAme = (currentPageTab) => {
  const allItems = [...menuItems, ...viewItems];

  const currentItem = allItems.find((item) => item.page === currentPageTab);
  return currentItem.name;
};
