import Stack from "@mui/material/Stack";
import "../../pages/ListView/ListView.css";

export const Message = ({ message, instruction, heightVh }) => {
  return (
    <>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
        sx={{
          height: heightVh ? heightVh + "vh" : "78vh",
          textAlign: "center",
        }}
      >
        <h3 className="textDark">{message}</h3>
        <h4 className="textDark">
          {instruction} <br />
          😊
        </h4>
      </Stack>
    </>
  );
};
